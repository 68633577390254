"use client";

import { useEffect, useState } from "react";

import { useTheme, UseThemeProps } from "next-themes";

import { Poppins } from "next/font/google";
import { useParams } from "next/navigation";

import { avatarStateType, countriesType, themeType, postAuthor } from "./types";

export const poppins = Poppins({
  weight: ["400", "600"],
  subsets: ["latin"],
  variable: "--font-poppins",
});

export function useCurrentLocale(): countriesType {
  return (useParams()?.locale as countriesType) || "sv";
}

export function useAuthorShortAlt(
  author: postAuthor,
  illustrationTranslaton: string,
) {
  return author.title !== undefined
    ? `${author.title}, ${illustrationTranslaton}.`
    : "";
}

// Hook to handle setting light or dark theme
export function usePreferredTheme() {
  const { resolvedTheme, setTheme, theme }: UseThemeProps = useTheme();
  const [preferredTheme, setPreferredTheme] = useState<themeType | undefined>(
    undefined,
  );

  // useEffect only runs on the client, so now we can safely show the UI
  useEffect(() => {
    if (theme === "system") {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        setPreferredTheme("dark");
      } else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
        setPreferredTheme("light");
      }
    } else if (theme !== "system") setPreferredTheme(theme as themeType);
  }, [theme]);
  // Handle meta tag for theme color
  useEffect(() => {
    let metaTag = document.querySelector('meta[name="theme-color"]');
    if (!metaTag) {
      metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "theme-color");
      document.head.appendChild(metaTag);
    }

    const isYellowBody = document.querySelector(".yellow-body") !== null;
    const isGreenBody = document.querySelector(".green-body") !== null;
    const isRedBody = document.querySelector(".red-body") !== null;
    const isBrownBody = document.querySelector(".brown-body") !== null;

    const lightModeColor = () => {
      if (isYellowBody) return "#f0c868";
      if (isGreenBody) return "#a9d9b3";
      if (isRedBody) return "#f7a7a4";
      if (isBrownBody) return "#F2B781";
      // default color is blue
      return "#7ca7ba";
    };

    metaTag.setAttribute(
      "content",
      preferredTheme === "light" ? lightModeColor() : "#0f172a",
    );
  }, [preferredTheme]);

  return { preferredTheme, setTheme, resolvedTheme };
}

export function useAvatarState() {
  const [avatarState, setAvatarState] = useState<avatarStateType>("photos");
  return { avatarState, setAvatarState };
}

// Hook to detect if user has scrolled past a certain threshold
export const useScrollTrigger = (threshold: number): boolean => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      setTrigger(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [threshold]);

  return trigger;
};

export default useScrollTrigger;
