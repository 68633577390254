"use client";
import React, { useRef, MouseEventHandler } from "react";

import { MdArrowForward as ArrowForwardIcon } from "react-icons/md";

import Image from "next/image";

import { canonicalBaseLanguageURLs, localizedPaths } from "i18n/utils";

import { CasePuffProps } from "lib/types";
import { useCurrentLocale } from "lib/utils";

import AnimatedLink from "./animated-link";
import { Chip } from "./chip";
import { Typography } from "./typography";

/**
 * Props for the CaseLinkCard component.
 */
interface CaseLinkCardProps {
  /**
   * Props for the CasePuff component.
   */
  casePuff: Partial<CasePuffProps>;
  /**
   * The children nodes of the component.
   */
  children?: React.ReactNode;
  /**
   * Additional CSS class name for the component.
   */
  className?: string;
  /**
   * The HTML component to be rendered.
   */
  component?: "div" | "li";
  /**
   * The heading level for the component.
   */
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  /**
   * Determines whether to show categories in the component.
   */
  showCategories?: boolean;
  /**
   * Determines whether to show a chip in the component.
   */
  showChip?: boolean;
  translations: {
    case: string;
  };
}

export const CaseLinkCard: React.FC<CaseLinkCardProps> = ({
  casePuff,
  children,
  className = "",
  component = "div",
  headingLevel = "h3",
  showCategories = false,
  showChip = true,
  translations,
}) => {
  const { name, puffImage, puffText } = casePuff || {};

  const linkRef = useRef<HTMLAnchorElement>(null);
  const t = translations;

  const handleCardClick: MouseEventHandler<HTMLDivElement | HTMLLIElement> = (
    event,
    // eslint-disable-next-line consistent-return
  ): void => {
    if (
      linkRef?.current !== undefined &&
      // @ts-ignore
      !linkRef?.current?.contains(event.target)
    ) {
      // @ts-ignore
      return linkRef?.current?.click();
    }
  };

  const headingWithLineBreaks = name?.split("   ");

  const CardComponent = component;

  const listItemClasses = component === "li" ? "m-[initial]" : "";

  const paddingClasses = `px-4 py-6 sm:p-6 md:p-8 ${
    puffImage?.url ? "pb-60 sm:pb-60 mdplus:pb-8" : "pb-8 md:pb-16"
  }`;

  const sharedClasses = `more-contrast-border group relative z-[10] flex min-h-[375px] cursor-pointer flex-col overflow-hidden rounded-lg bg-beige shadow-sm dark:bg-slate-800 case-card [&::after]:dark:brightness-75 ${className} `;

  const locale = useCurrentLocale();
  return (
    <CardComponent
      className={`${sharedClasses} ${paddingClasses} ${listItemClasses}`}
      onClick={handleCardClick}
    >
      <div>
        {!showCategories && showChip && (
          <div aria-hidden>
            <Chip color="yellow" label={t.case} />
          </div>
        )}

        {showCategories &&
          casePuff.serviceCategories &&
          casePuff.serviceCategories.length > 0 && (
            <ul className="m-0 flex list-none flex-row flex-wrap gap-2 p-0">
              {casePuff.serviceCategories.map((tag) => (
                <li className="m-0 p-0" key={tag.title}>
                  <Chip
                    className="m-0"
                    color="gray"
                    display="inline-flex"
                    label={tag?.title?.replace("   ", "")}
                    size="small"
                  />
                </li>
              ))}
            </ul>
          )}
        <AnimatedLink
          className="mt-4 flex flex-row justify-between gap-x-4 gap-y-2 focus-visible:!outline-none"
          href={`${canonicalBaseLanguageURLs(locale)}${localizedPaths(locale).case}/${casePuff.slug}`}
          ref={linkRef}
        >
          <div className="mb-3 ">
            <Typography
              className="relative z-10 inline w-fit max-w-prose rounded-sm bg-beige underline-offset-[0.15em] group-focus-within:underline group-hover:underline more-contrast:underline dark:bg-slate-800"
              component={headingLevel}
              variant="h3"
            >
              {/*  Hacky solution to insert soft hyphen when editors add triple space  */}
              {headingWithLineBreaks?.map((substring, index) =>
                headingWithLineBreaks.length > 1 && index > 0 ? (
                  <React.Fragment key={substring}>
                    &shy;{substring}
                  </React.Fragment>
                ) : (
                  <React.Fragment key={substring}>{substring}</React.Fragment>
                ),
              )}
              {"\u00A0"}
            </Typography>
          </div>
          <div className="relative z-10 h-fit rounded-sm bg-beige dark:bg-slate-800">
            <ArrowForwardIcon className="shrink-0 text-2xl opacity-70 group-focus-within:opacity-100 group-hover:opacity-100 more-contrast:opacity-100 lg:text-4xl" />
          </div>
        </AnimatedLink>
      </div>

      {puffText && (
        <div className="flex flex-col justify-between">
          <Typography className="mdplus:max-w-[60%]">{puffText}</Typography>
        </div>
      )}
      {children}
      {puffImage?.url && (
        <div className="absolute bottom-[-20px] right-[-50px] h-[45%] w-[80%] mdplus:bottom-[50px] mdplus:right-[-50px] mdplus:size-[45%]">
          <div className="relative aspect-square ">
            <Image
              alt=""
              className="absolute rounded-full object-cover opacity-90"
              fill
              sizes="(max-width:600px) 50vw, 25vw"
              src={puffImage?.url}
            />
          </div>
        </div>
      )}
    </CardComponent>
  );
};

export default CaseLinkCard;
